// types
import type { Nullable, Tutor } from '@revolutionprep/types'

// nuxt
import { useNuxtApp } from '#app'

export function usePage (title: Ref<string>) {
  /**
   * head tag
   * ==================================================================
   */
  useHead({
    title: `${title.value} | Revolution Prep`
  })

  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor } = useNuxtApp()

  /**
   * computed
   * ==================================================================
   */
  const actor = computed(() => {
    return $actor.core.actor.value as Nullable<Tutor>
  })
  return {
    actor
  }
}
